import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useQueryParam, NumberParam, StringParam } from 'use-query-params';

import { H3, H4, P } from '@styles/Typography';

import SEO from '@components/seo';
import { SearchIcon, SendIcon } from '@components/Icons';
import { Wrapper, Section } from '@components/Layout/styles';
import Breadcrumb from '@components/Breadcrumb';
import Link from '@components/Link';
import Pager from '@components/Pager';

import { friendlyDate } from '@utils/postData';

import bg from '@images/bg-404.svg';

import * as S from './_styles';

const BlogPage = () => {
	const [categoryParam, setCategoryParam] = useQueryParam(
		'category',
		StringParam
	);
	const [pageParam, setPageParam] = useQueryParam('page', NumberParam);
	const [searchParam, setSearchParam] = useQueryParam('search', StringParam);

	const [error, setError] = useState(false);
	const [filteredPosts, setFilteredPosts] = useState([]);
	const [perPage] = useState(6);
	const [posts, setPosts] = useState([]);
	const [search, setSearch] = useState('');

	const searchRef = useRef(null);

	const { categoryListQuery, meta, postListQuery } = useStaticQuery(graphql`
		query Posts {
			meta: allCosmicjsMeta(filter: { slug: { eq: "meta-blog" } }) {
				nodes {
					metadata {
						title
						description
						cover {
							imgix_url
						}
					}
				}
			}

			postListQuery: allCosmicjsPosts(
				sort: { order: DESC, fields: created_at }
			) {
				nodes {
					metadata {
						summary
						thumb {
							imgix_url
						}
						category {
							title
							slug
						}
					}
					created_at
					title
					slug
				}
			}

			categoryListQuery: allCosmicjsPostCategories {
				nodes {
					slug
					title
				}
			}
		}
	`);

	const categoryList = categoryListQuery.nodes;
	const postList = postListQuery.nodes;

	const {
		metadata: { cover, description: metaDescription, title: metaTitle },
	} = meta.nodes[0];

	const list = useMemo(() => (!filteredPosts.length ? posts : filteredPosts), [
		filteredPosts,
		posts,
	]);

	const forcePage = useMemo(() => {
		const maxPage = Math.ceil(list.length / perPage);

		if (pageParam > maxPage) {
			setPageParam(maxPage);

			return maxPage - 1;
		}

		if (pageParam <= 0) {
			setPageParam(1);

			return 0;
		}

		if (!pageParam) {
			return 0;
		}

		return pageParam - 1;
	}, [pageParam, list, perPage, setPageParam]);

	const createdAt = useCallback(date => {
		const { day, shortMonth } = friendlyDate(date);

		return (
			<S.Date>
				<span>{day}</span>
				<small> de {shortMonth}</small>
			</S.Date>
		);
	}, []);

	const handlePageClick = useCallback(
		({ selected }) => {
			setPageParam(selected + 1);
		},
		[setPageParam]
	);

	const filterBySearch = useCallback(
		term => {
			const filtered = postList.filter(
				({ title, metadata: { summary } }) =>
					title.toLowerCase().includes(term) ||
					summary.toLowerCase().includes(term)
			);

			setCategoryParam(undefined);

			if (!filtered.length) {
				setError(true);

				return;
			}

			setError(false);
			setFilteredPosts(filtered);
		},
		[postList, setCategoryParam]
	);

	const filterByCategory = useCallback(
		value => {
			setCategoryParam(value);
			setSearch(undefined);
			searchRef.current.value = '';

			if (categoryParam && categoryParam !== 'all') {
				const filtered = postList.filter(
					({
						metadata: {
							category: { slug },
						},
					}) => slug === categoryParam
				);

				setFilteredPosts(filtered);

				return;
			}

			setFilteredPosts([]);
			setPosts(postList);
		},
		[categoryParam, postList, setCategoryParam]
	);

	const setDefaultPage = useCallback(() => {
		if (!!filteredPosts.length && filteredPosts.length <= perPage) {
			setPageParam(1);
		}
	}, [filteredPosts, perPage, setPageParam]);

	const handleSearch = useCallback(() => {
		setSearchParam(search);
		filterBySearch(search);
		setPageParam(1);
	}, [filterBySearch, setSearchParam, search, setPageParam]);

	const handleSearchChange = useCallback(
		value => {
			if (!value) {
				setFilteredPosts([]);
				setPosts(postList);
			}

			setSearch(value);
		},
		[setSearch, setPosts, postList]
	);

	useEffect(() => {
		setDefaultPage();
	}, [setDefaultPage]);

	useEffect(() => {
		setPosts(postList);
	}, [setPosts, postList]);

	useEffect(() => {
		if (categoryParam) {
			filterByCategory(categoryParam);
			setSearchParam(undefined);
		}
	}, [categoryParam, filterByCategory, setSearchParam]);

	useEffect(() => {
		if (searchParam) {
			filterBySearch(searchParam);
			setCategoryParam(undefined);
			setSearch(searchParam);
		}
	}, [searchParam, filterBySearch, setCategoryParam]);

	useEffect(() => {
		if (pageParam) {
			setPageParam(pageParam);
		}
	}, [pageParam, setPageParam]);

	return (
		<>
			<SEO
				title={metaTitle || 'Blog'}
				description={metaDescription}
				cover={cover.imgix_url}
			/>

			<Wrapper>
				<Breadcrumb crumbs={[{ title: 'Blog', slug: 'blog' }]} />
			</Wrapper>

			<Section>
				<S.Wrapper>
					<S.Sidebox>
						<H4>Buscar no blog</H4>
						<S.TextField
							name="posts"
							onChange={e => handleSearchChange(e.target.value.toLowerCase())}
							onKeyDown={e => e.key === 'Enter' && handleSearch(e.target.value)}
							placeholder="Procure por um post"
							prefix={<SearchIcon />}
							value={search}
							register={searchRef}
							autoComplete="off"
						>
							<button onClick={e => handleSearch(e.target.value)} type="button">
								<SendIcon size={24} />
							</button>
						</S.TextField>

						<H4>Filtrar por categoria</H4>
						<S.CategoryFilter>
							<>
								<input
									type="radio"
									id="all"
									name="post-categories"
									value="all"
									checked={categoryParam === 'all' || !categoryParam}
									onChange={() => filterByCategory('all')}
								/>
								<label htmlFor="all">Todas</label>
							</>

							{categoryList.map(({ slug, title }) => (
								<React.Fragment key={slug}>
									<input
										type="radio"
										id={slug}
										name="post-categories"
										value={slug}
										checked={categoryParam === slug}
										onChange={() => filterByCategory(slug)}
									/>
									<label htmlFor={slug}>{title}</label>
								</React.Fragment>
							))}
						</S.CategoryFilter>
					</S.Sidebox>

					<S.MainContent>
						{list.length && !error ? (
							list
								.slice(
									pageParam ? (pageParam - 1) * perPage : 0,
									pageParam ? pageParam * perPage : perPage
								)
								.map(
									({
										title,
										created_at,
										slug,
										metadata: {
											summary,
											thumb: { imgix_url: thumb },
											category: { title: cat },
										},
									}) => (
										<S.Item key={slug}>
											{createdAt(created_at)}
											<S.Content>
												<S.Category>
													Categoria: <small>{cat}</small>
												</S.Category>

												<H3 as="h1">{title}</H3>

												<img src={thumb} alt={title} />

												<S.Summary
													dangerouslySetInnerHTML={{ __html: summary }}
												/>

												<Link to={slug} label="continuar lendo" withIcon />
											</S.Content>
										</S.Item>
									)
								)
						) : (
							<S.NotFound>
								<img src={bg} alt="Post não encontrado" />

								<H3>Nenhum post encontrado</H3>

								<P>
									Infelizmente não encontramos nenhum post com o termo{' '}
									<b>{searchParam}</b>.
								</P>
								<P>Por favor tente novamente com um termo diferente.</P>
							</S.NotFound>
						)}

						{!error && (
							<Pager
								handlePageClick={e => handlePageClick(e)}
								perPage={perPage}
								size={
									!filteredPosts.length ? posts.length : filteredPosts.length
								}
								forcePage={forcePage}
							/>
						)}
					</S.MainContent>
				</S.Wrapper>
			</Section>
		</>
	);
};

export default BlogPage;
