import styled from 'styled-components';

import { colors, media, spacing, toRem } from '@styles/Theme';

import { Wrapper as LWrapper } from '@components/Layout/styles';

import CSidebox from '@components/Sidebox';
import CTextField from '@components/TextField';

export const Wrapper = styled(LWrapper)`
	@media (${media.tablet}) {
		padding: ${spacing(0, 8)};
	}

	@media (${media.desktop}) {
		display: flex;
		align-items: flex-start;
		padding-left: ${spacing(15)};
	}
`;

export const MainContent = styled.div`
	@media (${media.desktop}) {
		flex: 1;
	}
`;

export const Content = styled.article`
	flex: 1;
	margin-bottom: ${spacing(3)};
	padding-bottom: ${spacing(3)};
	border-bottom: ${toRem(2)} dashed ${colors.lightGrey};

	img {
		width: 100%;
	}

	> h1 {
		margin-bottom: ${spacing(2)};
		line-height: 1.2;
	}

	@media (${media.desktop}) {
		> h1 {
			margin-bottom: ${spacing(3)};
		}
	}
`;

export const Item = styled.div`
	@media (${media.desktop}) {
		display: flex;
		align-items: flex-start;
		margin-right: ${spacing(4)};
	}
`;

export const Sidebox = styled(CSidebox)`
	width: 100%;
	max-width: unset;
	margin: ${spacing(3)} 0;

	p:last-child {
		margin: 0;
	}

	@media (${media.desktop}) {
		order: 1;
		width: ${toRem(320)};
		margin: 0;
	}
`;

export const Date = styled.div`
	width: fit-content;
	margin-bottom: ${spacing(1)};
	padding-bottom: ${spacing(1)};
	border-bottom: ${toRem(1)} solid ${colors.lightGrey};

	@media (${media.desktop}) {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-right: ${spacing(4)};

		span {
			font-weight: bold;
			font-size: ${toRem(40)};
			line-height: 1;
		}

		small {
			font-size: ${toRem(14)};
			white-space: nowrap;
		}
	}
`;

export const TextField = styled(CTextField)`
	button {
		color: ${colors.primary};
		background: none;
		border: none;
	}
`;

export const Category = styled.span`
	display: block;
	margin-bottom: ${spacing(2)};
	color: ${colors.lightGrey};
	font-weight: bold;
	font-size: ${toRem(14)};

	small {
		color: ${colors.primary};
	}
`;

export const Summary = styled.div`
	margin: ${spacing(2, 0)};
`;

export const CategoryFilter = styled.div`
	display: flex;
	flex-wrap: wrap;

	input {
		display: none;
	}

	label {
		margin: ${spacing(0, 1, 1, 0)};
		padding: ${spacing(0.5)};
		color: ${colors.lightGrey};
		font-size: ${toRem(12)};
		white-space: nowrap;
		text-transform: lowercase;
		border: ${toRem(1)} solid ${colors.lightGrey};
		border-radius: ${spacing(1)};
		cursor: pointer;
		transition: all 0.2s linear;
		user-select: none;

		&:hover {
			color: #fff;
			background-color: ${colors.primary};
			border-color: ${colors.primary};
		}
	}

	input:checked + label {
		color: #fff;
		background-color: ${colors.primary};
		border-color: ${colors.primary};
	}
`;

export const NotFound = styled.div`
	text-align: center;

	img {
		max-width: ${toRem(580)};
	}

	h3 {
		color: ${colors.primary};
	}

	b {
		color: ${colors.primary};
	}
`;
