import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';

import { ChevronRightIcon, ChevronLeftIcon } from '@components/Icons';

import * as S from './styles';

const ChooseNav = ({ side }) => {
	if (side === 'left') {
		return (
			<>
				<ChevronLeftIcon />
				<span>Anterior</span>
			</>
		);
	}

	return (
		<>
			<span>Próximo</span>
			<ChevronRightIcon />
		</>
	);
};

const Pager = ({ handlePageClick, perPage, size, forcePage }) => {
	const [quantity, setQuantity] = useState();

	const handleQuantity = useCallback(() => {
		if (window.innerWidth < 768) {
			return setQuantity(1);
		}

		return setQuantity(2);
	}, []);

	useEffect(() => {
		window.addEventListener('resize', handleQuantity);

		return () => window.removeEventListener('resize', handleQuantity);
	}, [handleQuantity]);

	useEffect(() => {
		handleQuantity();
	}, [handleQuantity]);

	return (
		<S.Container>
			<ReactPaginate
				activeClassName="active"
				breakClassName="break-me"
				breakLabel="..."
				containerClassName="pagination"
				forcePage={forcePage}
				marginPagesDisplayed={quantity}
				nextLabel={<ChooseNav side="right" />}
				onPageChange={e => handlePageClick(e)}
				pageCount={Math.ceil(size / perPage)}
				pageRangeDisplayed={quantity}
				previousLabel={<ChooseNav side="left" />}
				subContainerClassName="pages pagination"
			/>
		</S.Container>
	);
};

Pager.propTypes = {
	handlePageClick: PropTypes.func.isRequired,
	forcePage: PropTypes.number,
	perPage: PropTypes.number.isRequired,
	size: PropTypes.number.isRequired,
};

Pager.defaultProps = {
	forcePage: 0,
};

ChooseNav.propTypes = {
	side: PropTypes.string.isRequired,
};

export default Pager;
