import styled from 'styled-components';
import { colors, media, spacing } from '@styles/Theme';

export const Container = styled.div`
	user-select: none;

	ul {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
	}

	li {
		margin-right: ${spacing(1)};

		&:first-child,
		&:last-child {
			position: absolute;
		}

		&:first-child {
			left: 0;
		}

		&:last-child {
			right: 0;
			margin: 0;
		}

		> a {
			display: block;
			padding: ${spacing(0, 1)};
			color: ${colors.darkGrey};
			cursor: default;

			@media (${media.mobileOnly}) {
				/* padding: ${spacing(0.5, 1.5)}; */
			}
		}

		&.disabled > a {
			color: ${colors.lightGrey};
		}

		&.previous > a,
		&.next > a {
			display: flex;
			align-items: center;
			padding: 0;

			svg {
				width: ${spacing(3)};
			}

			@media (${media.mobileOnly}) {
				span {
					display: none;
				}

				svg {
					width: ${spacing(5)};
				}
			}
		}

		&.active > a {
			color: #fff;
			background-color: ${colors.primary};
			border-radius: ${spacing(0.5)};
		}

		&:not(.active):not(.disabled) > a {
			cursor: pointer;
		}
	}

	@media (${media.desktop}) {
		margin: ${spacing(4, 4, 4, 9)};
	}
`;
